import React from 'react';
import { Box, Typography, Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';

const UserDetailsContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  padding: '4px 8px',
});

const UserInfo = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '2px',
});

const UserName = styled(Typography)({
  fontWeight: 600,
  fontSize: '0.9rem',
  lineHeight: 1.2,
});

const UserRole = styled(Typography)({
  color: 'text.secondary',
  fontSize: '0.8rem',
  lineHeight: 1.2,
});

const UserDetails = () => {
  return (
    <UserDetailsContainer>
      <Avatar sx={{ width: 32, height: 32 }}>JD</Avatar>
      <UserInfo>
        <UserName>John Doe</UserName>
        <UserRole>Administrator</UserRole>
      </UserInfo>
    </UserDetailsContainer>
  );
};

export default UserDetails;