import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  styled
} from '@mui/material';

const StyledFormControl = styled(FormControl)({
  minWidth: 200,
  '& .MuiInputLabel-root': {
    transform: 'translate(14px, 8px) scale(0.75)',  // Adjust label position
    '&.Mui-focused, &.MuiFormLabel-filled': {
      transform: 'translate(14px, -9px) scale(0.75)',
    }
  },
  '& .MuiSelect-select': {
    paddingTop: '8px',   // Reduced padding
    paddingBottom: '8px',
    backgroundColor: '#fff',
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#fff',
  }
});

const BookSelector = () => {
  const [book, setBook] = React.useState('');

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <StyledFormControl size="small">
        <InputLabel id="book-select-label">Select Book</InputLabel>
        <Select
          labelId="book-select-label"
          id="book-select"
          value={book}
          label="Select Book"
          onChange={(e) => setBook(e.target.value)}
        >
          <MenuItem value="book1">Book 1</MenuItem>
          <MenuItem value="book2">Book 2</MenuItem>
          <MenuItem value="book3">Book 3</MenuItem>
        </Select>
      </StyledFormControl>
    </Box>
  );
};

export default BookSelector;