import React from 'react';
import {
  AppBar,
  Toolbar,
  Box,
  styled
} from '@mui/material';
import UserDetails from './UserDetails';
import NotificationsDropdown from './NotificationsDropdown';
import BookSelector from './BookSelector';

const StyledAppBar = styled(AppBar)({
  backgroundColor: '#fff',
  color: '#000',
  boxShadow: 'none',
  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  height: '65px',
  '& .MuiToolbar-root': {
    height: '100%',
    minHeight: '65px',
    padding: '0 16px',
  }
});

const NavGroup = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '12px', // Reduced spacing between elements
});

const TopBar = () => {
  return (
    <StyledAppBar position="fixed">
      <Toolbar>
        <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', gap: 2 }}>
          <BookSelector />
        </Box>
        <NavGroup>
          <NotificationsDropdown />
          <UserDetails />
        </NavGroup>
      </Toolbar>
    </StyledAppBar>
  );
};

export default TopBar;