import React from 'react';
import { 
  Paper, 
  Typography, 
  Box,
  Button,
  useTheme,
  styled 
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import welcomeBg from '../../assets/patterns/welcome-bg.svg';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '300px',
  borderRadius: theme.shape.borderRadius * 2,
  overflow: 'hidden',
  marginBottom: theme.spacing(4),
  display: 'flex',
  backgroundImage: `url(${welcomeBg})`,
  backgroundSize: 'cover',
  backgroundPosition: 'right center',
  backgroundColor: theme.palette.background.paper,
  boxShadow: 'none',
  border: '1px solid rgba(0,0,0,0.05)',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `linear-gradient(120deg, 
      ${theme.palette.background.paper} 0%, 
      ${theme.palette.background.paper}CC 45%, 
      ${theme.palette.background.paper}00 100%)`,
    zIndex: 1,
    animation: 'gradientMove 8s ease infinite',
  },
  '@keyframes gradientMove': {
    '0%': {
      backgroundPosition: '0% 50%'
    },
    '50%': {
      backgroundPosition: '100% 50%'
    },
    '100%': {
      backgroundPosition: '0% 50%'
    }
  }
}));

const ContentBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  zIndex: 2,
  padding: theme.spacing(4, 5),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '60%',
  height: '100%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  }
}));

const GradientTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Playfair Display',
  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  marginBottom: theme.spacing(1)
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3),
  width: 'fit-content',
  padding: theme.spacing(1, 3),
  borderRadius: 25,
  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  color: theme.palette.common.white,
  textTransform: 'none',
  fontSize: '1rem',
  fontWeight: 500,
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateX(5px)',
    boxShadow: `0 4px 20px ${theme.palette.primary.main}40`
  }
}));

const WelcomePanel = ({ userName = "Author" }) => {
  const theme = useTheme();
  const currentHour = new Date().getHours();
  
  const getGreeting = () => {
    if (currentHour < 12) return "Good Morning";
    if (currentHour < 18) return "Good Afternoon";
    return "Good Evening";
  };

  return (
    <StyledPaper elevation={0}>
      <ContentBox>
        <GradientTypography variant="h3">
          {getGreeting()},
        </GradientTypography>
        <Typography 
          variant="h4" 
          sx={{ 
            mb: 2,
            fontWeight: 600,
            color: theme.palette.text.primary
          }}
        >
          {userName}
        </Typography>
        <Typography 
          variant="body1" 
          sx={{ 
            color: theme.palette.text.secondary,
            maxWidth: '80%',
            lineHeight: 1.6
          }}
        >
          Ready to continue crafting your story? Your creative journey awaits...
        </Typography>
        <StyledButton
          endIcon={<ArrowForwardIcon />}
        >
          Continue Writing
        </StyledButton>
      </ContentBox>
    </StyledPaper>
  );
};

export default WelcomePanel;