import React from 'react';
import { 
  Drawer, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText, 
  Typography, 
  Box, 
  Button,
  Divider,
  useTheme,
  styled
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { getRoutesBySection } from '../../routes';
import LogoutIcon from '@mui/icons-material/Logout';
import sidebarPattern from '../../assets/patterns/sidebar-pattern.svg';
import Logo from '../common/Logo';


import {
  Person as PersonIcon,
  Settings as SettingsIcon,
  Build as BuildIcon,
  Help as HelpIcon,
  // ... import other icons as needed
} from '@mui/icons-material';

const DRAWER_WIDTH = 280;

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: DRAWER_WIDTH,
    boxSizing: 'border-box',
    backgroundColor: theme.palette.background.paper,
    backgroundImage: `url(${sidebarPattern})`,
    backgroundSize: 'cover',
    borderRight: 'none',
    boxShadow: '4px 0 24px rgba(0, 0, 0, 0.05)',
  }
}));
const LogoSection = styled(Box)(({ theme }) => ({
  height: 80,
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 3),
  backgroundColor: theme.palette.background.paper,
  borderRight: `1px solid ${theme.palette.divider}`,
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '0.75rem',
  fontWeight: 600,
  textTransform: 'uppercase',
  letterSpacing: '0.1em',
  padding: theme.spacing(0, 3),
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(1),
}));

const StyledListItem = styled(ListItem)(({ theme, selected }) => ({
  margin: theme.spacing(0.5, 2),
  borderRadius: theme.shape.borderRadius * 3,
  transition: 'all 0.3s ease',
  position: 'relative',
  overflow: 'hidden',
  '&::before': selected ? {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `linear-gradient(45deg, ${theme.palette.primary.main}20, ${theme.palette.secondary.main}20)`,
    borderRadius: 'inherit',
    zIndex: 0,
  } : {},
  '&:hover': {
    backgroundColor: `${theme.palette.primary.main}10`,
    transform: 'translateX(5px)',
  },
  '& .MuiListItemIcon-root': {
    color: selected ? theme.palette.primary.main : theme.palette.text.secondary,
    minWidth: 40,
    zIndex: 1,
  },
  '& .MuiListItemText-primary': {
    color: selected ? theme.palette.primary.main : theme.palette.text.primary,
    fontWeight: selected ? 600 : 400,
    fontSize: '0.95rem',
    zIndex: 1,
  }
}));

const LogoutButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2),
  borderRadius: 8,
  padding: theme.spacing(1.2, 3),
  background: theme.palette.primary.secondary,
  color: theme.palette.common.dark,
  textTransform: 'none',
  fontSize: '0.95rem',
  fontWeight: 500,
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: `0 4px 20px ${theme.palette.primary.main}40`,
  }
}));

const sections = [
  { id: 'main', label: 'Main' },
  { id: 'user', label: 'User' },
  { id: 'configure', label: 'Configure' },
  { id: 'tools', label: 'Tools' },
  { id: 'help', label: 'Help & Support' }
];

const Sidebar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { signOut, user } = useAuth();

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <StyledDrawer variant="permanent">
      <Box sx={{ 
        height: 160, 
        display: 'flex', 
        alignItems: 'center', 
        px: 1,
        background: 'rgba(255,255,255,0.8)',
        backdropFilter: 'blur(10px)',
      }}>
      <Logo />
      </Box>
      
      <Box sx={{ 
        overflow: 'auto', 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column' 
      }}>
        <Box sx={{ flexGrow: 1 }}>
          {sections.map((section) => (
            <Box key={section.id}>
              <SectionTitle>
                {section.label}
              </SectionTitle>
              <List>
                {getRoutesBySection(section.id).map((route) => (
                  <StyledListItem
                    button
                    key={route.path}
                    selected={location.pathname === route.path}
                    onClick={() => handleNavigation(route.path)}
                  >
                    <ListItemIcon>
                      {route.icon}
                    </ListItemIcon>
                    <ListItemText primary={route.label} />
                  </StyledListItem>
                ))}
              </List>
            </Box>
          ))}
        </Box>

        <Box sx={{ p: 2 }}>
          <Divider sx={{ mb: 2 }} />
          <Box sx={{ 
            px: 2, 
            mb: 2, 
            display: 'flex', 
            alignItems: 'center',
            gap: 1
          }}>
            <Typography 
              variant="body2" 
              color="textSecondary"
              sx={{ 
                overflow: 'hidden', 
                textOverflow: 'ellipsis' 
              }}
            >
              {user?.email}
            </Typography>
          </Box>
          <LogoutButton
            fullWidth
            startIcon={<LogoutIcon />}
            onClick={handleLogout}
          >
            Sign Out
          </LogoutButton>
        </Box>
      </Box>
    </StyledDrawer>
  );
};

export default Sidebar;