import React, { useState, useEffect } from 'react';
import {
  Container,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Link,
  Box,
  Alert,
  CircularProgress,
  useTheme,
  styled
} from '@mui/material';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import Logo2 from '../common/Logo2';
import loginBg from '../../assets/patterns/login-bg.svg';

// Styled components
const LoginContainer = styled(Container)(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background: `url(${loginBg})`,
  backgroundSize: 'cover',
  animation: 'fadeIn 0.5s ease-in',
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 }
  }
}));

const LoginCard = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 450,
  borderRadius: theme.shape.borderRadius * 2,
  backdropFilter: 'blur(10px)',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'translateY(-5px)'
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    transition: 'transform 0.2s ease',
    '&:hover': {
      transform: 'translateY(-2px)'
    },
    '&.Mui-focused': {
      transform: 'translateY(-2px)'
    }
  }
}));

const AnimatedButton = styled(Button)(({ theme }) => ({
  background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.light} 90%)`,
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 20px rgba(255, 137, 163, 0.4)'
  }
}));

const Login = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { signIn, user } = useAuth();
  const from = location.state?.from?.pathname || '/dashboard';

  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      navigate(from, { replace: true });
    }
  }, [user, navigate, from]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    if (error) setError(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setError(null);
      await signIn(formData);
    } catch (error) {
      console.error('Login error:', error);
      setError(error.message || 'Invalid email or password');
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoginContainer maxWidth={false}>
      <Box sx={{ 
        mb: 6, 
        transform: 'scale(1.2)',
        transition: 'transform 0.3s ease',
        '&:hover': {
          transform: 'scale(1.25)'
        }
      }}>
        <Logo2 />
      </Box>
      
      <LoginCard>
        <CardContent sx={{ p: 4 }}>
          <Typography 
            variant="h4" 
            align="center" 
            gutterBottom
            sx={{ 
              fontFamily: 'Playfair Display',
              fontWeight: 700,
              background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              mb: 4
            }}
          >
            Welcome to the novel making app - myPages
          </Typography>
          
          <form onSubmit={handleSubmit}>
            <StyledTextField
              fullWidth
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              required
              disabled={loading}
              sx={{ mb: 3 }}
            />
            
            <StyledTextField
              fullWidth
              label="Password"
              name="password"
              type="password"
              value={formData.password}
              onChange={handleChange}
              required
              disabled={loading}
              sx={{ mb: 3 }}
            />

            {error && (
              <Alert 
                severity="error" 
                sx={{ 
                  mb: 3,
                  borderRadius: theme.shape.borderRadius * 1.5
                }}
              >
                {error}
              </Alert>
            )}

            <AnimatedButton
              type="submit"
              fullWidth
              disabled={loading}
              sx={{ mb: 3, height: 48 }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Sign In'
              )}
            </AnimatedButton>

            <Box sx={{ 
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
              <Link
                component={RouterLink}
                to="/register"
                sx={{ 
                  color: theme.palette.primary.main,
                  textDecoration: 'none',
                  fontWeight: 500,
                  transition: 'all 0.2s ease',
                  '&:hover': {
                    color: theme.palette.primary.dark,
                    transform: 'translateY(-1px)'
                  }
                }}
              >
                Create Account
              </Link>
              <Link
                component={RouterLink}
                to="/forgot-password"
                sx={{ 
                  color: theme.palette.primary.main,
                  textDecoration: 'none',
                  fontWeight: 500,
                  transition: 'all 0.2s ease',
                  '&:hover': {
                    color: theme.palette.primary.dark,
                    transform: 'translateY(-1px)'
                  }
                }}
              >
                Forgot Password?
              </Link>
            </Box>
          </form>
        </CardContent>
      </LoginCard>

      <Typography 
        variant="body2" 
        align="center"
        sx={{ 
          mt: 4, 
          color: 'rgba(0,0,0,0.6)',
          backdropFilter: 'blur(5px)',
          padding: '8px 16px',
          borderRadius: theme.shape.borderRadius,
          backgroundColor: 'rgba(255,255,255,0.5)'
        }}
      >
        By signing in, you agree to our Terms of Service and Privacy Policy
      </Typography>
    </LoginContainer>
  );
};

export default Login;